import styled from 'styled-components';
import { px, th } from '../../lib/common';
import { ClickRescale } from '../ClickRescale';

export const Container = styled.div<{ topOverlay?: boolean }>`
    z-index: ${(props) => {
        if (props.topOverlay) {
            return 5;
        }
    }};
    position: absolute;
    top: 0;
    left: -1px;
    width: 101%;
    box-sizing: border-box;
    background-color: ${th('header.background')};
    color: ${th('header.foreground')};
    padding-top: ${px(50)};
    padding-left: ${px(28)};
    padding-right: ${px(28)};
    background-image: linear-gradient(
        to bottom,
        ${th('header.gradient.top')},
        ${th('header.gradient.bottom')}
    );
    border-bottom-left-radius: ${px(138)};
    border-bottom-right-radius: ${px(138)};
`;

export const HeaderIcon = styled(ClickRescale)`
    background-size: contain;
    background-repeat: no-repeat;
    cursor: pointer;
    width: ${px(100)};
    height: ${px(100)};
`;

HeaderIcon.defaultProps = {
    disabledLook: true,
};

export const HeaderDetailsContainer = styled.div`
    display: flex;
    flex-direction: row;
`;

export const HeaderDetailsLabel = styled.div`
    flex: 1;
    height: ${px(100)};
    font-size: ${px(40)};
    font-weight: 900;
    line-height: ${px(100)};
    padding-left: ${px(50)};
`;

export const HeaderAccountContainer = styled.div`
    display: flex;
    margin-top: ${px(80)};
    padding-bottom: ${px(60)};
`;
