import styled, { css, keyframes } from 'styled-components';
import { px } from '../../lib/common';
import { ReelSpin } from '../../stores';

export const glow = keyframes`
    from {
        box-shadow: 0 0 20px white;
    }
    to {
        box-shadow: 0 0 0px white;
    }
`;

export const innerGlow = keyframes`
    from {
        opacity: 0
    }
    to {
        opacity: 0.8;
    }
`;

export const cssGlow = css`
    animation: ${glow} 300ms ease-in-out infinite alternate;
    &:after {
        content: '';
        opacity: 0;
        animation: ${innerGlow} 300ms linear 6 alternate;
        position: absolute;
        background-color: white;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
`;

export const createSlide = (start: string, end: string) => keyframes`
    0% { top: ${start}; }
    100% { top: ${end}; }
`;

export const calculateTop = (height: number, shift: number) =>
    -((height + 8) * shift + 4) + 'px';

export const ReelContainer = styled.div<{ blink?: boolean }>`
    width: ${px(350)};
    background-color: white;
    border-radius: ${px(16)};
    border: ${px(16)} solid #e7e7e7;
    overflow: hidden;
    box-sizing: border-box;
    position: relative;
    box-shadow: 0 0 20px transparent;
    ${(props) => props.blink && cssGlow}
`;

export const ReelImage = styled.img`
    width: 100%;
    box-sizing: border-box;
    border-bottom-width: ${px(10)};
    border-bottom-style: solid;
    border-image-source: linear-gradient(
        to top,
        #e0e0e0,
        #a1b9d4,
        #fcfcfc,
        #dcdcdc,
        #fff
    );
    border-image-slice: 1;

    &:last-child {
        border-image-source: none;
    }
`;

export const ReelSpinner = styled.div<{ itemHeight: number; size: number }>`
    position: absolute;
    width: 100%;
    height: 100%;
    top: ${(props) => calculateTop(props.itemHeight, props.size - 3)};
    left: 0;
    animation-duration: 400ms;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
    animation-name: ${(props) => {
        const from = calculateTop(props.itemHeight, props.size - 3);
        return createSlide(from, '0px');
    }};

    ${ReelImage} {
        border-image-source: none;
    }
`;

export const ReelOutput = styled.div<{
    itemHeight: number;
    shift: number;
    spin: ReelSpin;
}>`
    width: 100%;
    position: absolute;
    left: 0;
    ${(props) => {
        const offset = props.shift + 1;

        if (props.spin === 'slide-up') {
            const start = calculateTop(props.itemHeight, offset - 1);
            const end = calculateTop(props.itemHeight, offset);
            return css`
                top: ${end};
                animation: 400ms ease-in-out 0s 1 ${createSlide(start, end)};
            `;
        }

        return css`
            top: ${calculateTop(props.itemHeight, offset)};
            transition: top 400ms ease-in-out;
        `;
    }}
`;
