import { FC, ReactNode } from 'react';
import { useSnapshot } from 'reactish-state';
import { AppContainer as ThemedAppContainer } from '../../components/AppContainer';
import { useDelayedRender } from '../../hooks/use-delayed-render';
import * as selectors from '../../stores/selectors';

export type AppContainerProps = {
    children?: ReactNode;
};

export const AppContainer: FC<AppContainerProps> = (props) => {
    const winLevel = useSnapshot(selectors.winLevel);

    const isPreloading = useSnapshot(selectors.isPreloading);

    const isAppRendered = useDelayedRender(!isPreloading, 350);

    if (!isAppRendered) {
        return null;
    }

    return <ThemedAppContainer {...props} winLevel={winLevel} />;
};
