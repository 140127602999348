import i18n from 'i18next';
import backend, { HttpBackendOptions } from 'i18next-http-backend';
import detector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

export type Translation = Record<string, unknown>;

export const getTranslation = (lng: string): Promise<Translation> =>
    import(`../locales/${lng}.json`)
        .then((result) => result.default)
        .catch(() => ({}));

export const getComposedTranslation = async (lng: string | null) => {
    const $lng = lng || '';
    const alt = $lng.split('-').shift() as string;
    const translations = await Promise.all(
        Array.from(new Set(['en', $lng, alt]))
            .filter((v) => v)
            .map(getTranslation)
    );

    return translations.reduce(
        (result, translation) => ({
            ...result,
            ...translation,
        }),
        {} as Translation
    );
};

export const createData = <T>(data: T, status: number) => ({
    data: JSON.stringify(data),
    status,
});

export const backendRequest: HttpBackendOptions['request'] = (
    _options,
    lng,
    _payload,
    callback
) => {
    try {
        getComposedTranslation(lng).then((data) => {
            callback(null, createData(data, 200));
        });
    } catch (error) {
        callback(error, createData(error, 500));
    }
};

i18n.use(backend)
    .use(detector)
    .use(initReactI18next)
    .init({
        detection: {
            lookupQuerystring: 'language',
        },
        interpolation: {
            escapeValue: false, // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
        },
        backend: {
            loadPath: '{{lng}}',
            request: backendRequest,
        } as HttpBackendOptions,
    });

export default i18n;
