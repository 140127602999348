import { FC, HTMLAttributes } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { px, th } from '../../lib/common';

export type AccountFieldType = 'bank' | 'bet' | 'win';

export type AccountFieldProps = HTMLAttributes<HTMLDivElement> & {
    type: AccountFieldType;
};

export const AccountFieldContainer = styled.div`
    display: flex;
    text-align: center;
    font-weight: 500;
`;

export const AccountFieldIconContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    height: ${px(100)};
    margin-right: ${px(22)};
`;

export const AccountFieldIcon = styled.div<{ type: AccountFieldType }>`
    background-image: ${(props) => {
        const type = ('icons.' + props.type) as `icons.${AccountFieldType}`;
        const url = props.theme[type];
        return `url("${url}")`;
    }};
    background-repeat: no-repeat;
    background-size: contain;
    width: ${px(55)};
    height: ${px(55)};
`;

export const AccountFieldBox = styled.div`
    background-color: ${th('account.box.background')};
    outline-style: solid;
    outline-width: ${px(2)};
    outline-color: ${th('account.box.border-color')};
    width: ${px(325)};
    height: ${px(100)};
    font-size: ${px(40)};
    line-height: ${px(101.2)};
    letter-spacing: ${px(1.2)};
`;

export const AccountFieldLabel = styled.div`
    margin-top: ${px(27)};
    color: ${th('account.label.color')};
`;

export const AccountField: FC<AccountFieldProps> = ({
    type,
    children,
    ...props
}) => {
    const { t } = useTranslation();

    return (
        <AccountFieldContainer {...props}>
            <AccountFieldIconContainer>
                <AccountFieldIcon type={type} />
            </AccountFieldIconContainer>
            <div>
                <AccountFieldBox>{children}</AccountFieldBox>
                <AccountFieldLabel>{t('account.' + type)}</AccountFieldLabel>
            </div>
        </AccountFieldContainer>
    );
};
