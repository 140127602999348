import styled from 'styled-components';
import { px, rpx, th } from '../../lib/common';

export type VisibilityProps = {
    isOpen: boolean;
};

export const PayTableDrawContainer = styled.div`
    text-align: center;
`;

export const PayTableDrawContent = styled.div<VisibilityProps>`
    height: ${(props) => (props.isOpen ? rpx(1808) + 'px' : 0)};
    opacity: ${(props) => (props.isOpen ? 1 : 0)};
    background-image: ${th('pay-table.cover')};
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    margin-left: -${px(24)};
    margin-right: -${px(24)};
    border-bottom-left-radius: ${px(10)};
    border-bottom-right-radius: ${px(10)};
    transition: 200ms ease-in-out height, 1000ms ease-in-out opacity;
    overflow: hidden;
    user-select: none;
`;

export const PayTableDrawSeparator = styled.div`
    width: 90%;
    background-color: ${th('pay-table.separator.background')};
    border-style: solid;
    border-width: ${px(1)};
    border-color: ${th('pay-table.separator.background')};
    display: inline-block;
`;

export const PayTableLabelContainer = styled.div`
    display: flex;
    cursor: pointer;

    padding-left: ${px(85)};
    padding-right: ${px(85)};
    user-select: none;
`;

export const PayTableDrawChevronContainer = styled.div`
    height: ${px(100)};
    display: flex;
    flex-direction: row;
    align-items: center;
`;

export const PayTableDrawChevron = styled.div<VisibilityProps>`
    background-image: ${th('icons.chevron-up')};
    width: ${px(44)};
    height: ${px(29)};
    background-size: contain;
    background-repeat: no-repeat;
    transform: ${(props) => `rotate(${props.isOpen ? 0 : 180}deg)`};
`;

export const PayTableDrawLabel = styled.div`
    flex: 1;
    font-weight: 900;
    font-size: ${px(40)};
    line-height: ${px(100)};
`;

export const PayTableDrawClickToSee = styled.span`
    color: ${th('pay-table.click.color')};
    font-weight: 500;
`;
