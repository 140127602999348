import { useEffect } from 'react';
import { store } from '../stores';

export const useAbort = () => {
    useEffect(() => {
        return () => {
            const controllers = store.get().aborters;
            for (const key in controllers) {
                controllers[key].abort();
            }
        };
    }, []);
};
