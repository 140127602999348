import { DefaultTheme } from 'styled-components';
import { store } from '.';
import { createSignalPromise } from '../lib/abort';

const { $set } = store;

export const getTheme = (style: string) =>
    import(`../THEME-${style}/theme`)
        .catch(() => import('../THEME-TTTR/theme'))
        .then((result) => result.default as DefaultTheme);

export const loadTheme = async (signal?: AbortSignal) => {
    $set((state) => (state.themeLoading = true));

    const params = new URLSearchParams(window.location.search);
    const style = params.get('style') || 'TTTR';
    const loaded = await Promise.race([
        getTheme(style),
        createSignalPromise(signal),
    ]);

    $set((state) => {
        state.theme = {
            ...state.theme,
            ...(loaded as DefaultTheme),
        };
        state.themeLoading = false;
    });
};
