import { captureException } from '@sentry/react';
import delay from 'delay';
import { store } from '.';
import { createSignalPromise } from '../lib/abort';
import { getHeaders } from '../lib/get-headers';
import { getBlinks, getWinState, parseWheels } from '../lib/get-win-state';
import { bgm, effects, waitToEnd } from '../lib/sounds';
import { getShifts, getSpinOffsets } from '../lib/spin';
import { bustedToQuery } from '../lib/to-query';
import { xFetch } from '../lib/xfetch';
import { BetParams, BetResponse } from '../types/api';
import { resetWinnings } from './bet';

const { $set, get } = store;

export const playWinCount = async (loop = 1, signal?: AbortSignal) => {
    const signalPromise = createSignalPromise(signal);

    const promises: Promise<unknown>[] = [signalPromise];
    const ids: number[] = [];

    try {
        for (let i = 0; i < loop; i++) {
            const id = effects.play('win-count');
            const promise = waitToEnd(effects, id);
            ids.push(id);
            promises.push(promise);
            await Promise.race([promise, delay(1500), signalPromise]);
        }

        await Promise.all(promises);
    } catch (error) {
        for (const id of ids.slice(0, -1)) {
            effects.stop(id);
        }
    }
};

export const stopReel = (index: number) => {
    $set((state) => {
        state.sfx.push(effects.play('reel-stop'));
        state.reels.spins[index] = 'slide-up';
    });
};

export const stopSfx = () => {
    for (const id of get().sfx) {
        effects.stop(id);
    }
};

export const sendBet = async () => {
    const data = get();

    const betParams: BetParams = {
        bet: data.payLines * data.betPerLine,
        denom: data.denominations[data.denominationIndex],
        freeSpin: false,
        game_id: data.gameId,
        key: data.betKey,
        payLines: data.payLines,
        wagerType: data.wagerType,
    };

    const params = bustedToQuery(betParams);
    const headers = getHeaders();

    const betController = new AbortController();

    $set((state) => {
        state.aborters['winSfx']?.abort();
        state.aborters['bet'] = betController;
        state.fetchBet = 'loading';
    });

    try {
        const fetched = await xFetch('/api/bet?' + params, {
            headers,
            signal: betController.signal,
        });

        const { msg, data, status }: BetResponse = await fetched.json();

        if (status === 'success') {
            $set((state) => {
                state.reels.wheels = data.wheels;
                state.reels.wins = data.win_array;
                state.reels.shifts = [0, 0, 0];
            });
            return data;
        }

        $set((state) => (state.error = msg));
    } catch (error) {
        const e = error as Error;

        if (e?.name !== 'AbortError') {
            $set((state) => (state.error = 'error.unexpectedError'));
        }
    } finally {
        $set((state) => {
            delete state.aborters['bet'];
            state.fetchBet = 'complete';
        });
    }
};

export const spin = async () => {
    const controller = new AbortController();

    const delayOptions = { signal: controller.signal };

    resetWinnings();

    $set((state) => {
        state.reels.spins = ['spin', 'spin', 'spin'];
        state.aborters.spin = controller;
    });

    $set((state) => state.sfx.push(effects.play('reel-spin')));

    const data = await sendBet();

    const { theme } = get();

    const suspense = theme.isSuspense(
        parseWheels(data?.wheels || '', data?.win_array || [])
    );

    const shifted = getShifts(data?.wheelshift || ['n', 'n', 'n']);

    try {
        if (data && !controller.signal.aborted) {
            await delay(1500, delayOptions);

            stopReel(0);

            await delay(1500, delayOptions);

            stopReel(1);

            if (suspense) {
                bgm.pause();

                const id = effects.play('suspense');

                $set((state) => {
                    state.sfx.push(id);
                    state.reels.suspense = true;
                    state.reels.overlays[2] = true;
                });

                await Promise.race([
                    delay(3000, delayOptions),
                    waitToEnd(effects, id, controller.signal),
                ]);

                effects.stop(id);
            } else {
                await delay(1100, delayOptions);
            }

            stopReel(2);

            if (suspense) {
                await delay(400, delayOptions);
            }
        }
    } catch (error) {
        const e = error as Error;
        if (e?.name !== 'AbortError') {
            captureException(error);
        }
    } finally {
        if (controller.signal.aborted) {
            stopSfx();
        }

        $set((state) => {
            delete state.aborters['spin'];

            state.reels.spins = ['stop', 'stop', 'stop'];
            state.reels.overlays = [false, false, false];
            state.reels.suspense = false;
            state.reels.shifts = shifted;
            state.reels.spinOffsets = getSpinOffsets(
                3,
                theme['reel.images'].length
            );

            if (data) {
                state.payouts = data.payouts;
                state.won = data.won;
                state.reels.highlights = data.pl_array;
                state.winnings = data.winnings;
                state.cash = data.cashValue;
                state.value = data.accountValue;
                state.bonus = data.bonusBalance;
                state.round = data.round || 0;

                state.reels.blinks = getBlinks(data.payouts);
            }
        });

        if (data?.won) {
            const levels = theme['win.levels'];
            const level = getWinState(levels, data.payouts);

            const winSfxController = new AbortController();

            $set((state) => {
                state.aborters['winSfx'] = winSfxController;
            });

            if (level === levels.length) {
                effects.play('big-win');

                await Promise.race([
                    delay(9000),
                    playWinCount(100, winSfxController.signal),
                ]);

                winSfxController.abort();
            } else {
                bgm.pause();
                await playWinCount(1, winSfxController.signal);
            }

            $set((state) => {
                delete state.aborters['winSfx'];
            });
        }

        if (!bgm.playing()) {
            bgm.play();
        }
    }
};

export const stop = () => {
    get().aborters.spin?.abort();
};
