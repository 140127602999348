import { FC } from 'react';
import styled from 'styled-components';
import { px, th } from '../../lib/common';
import { ClickRescale, ClickRescaleProps } from '../ClickRescale';

export type BetButtonProps = ClickRescaleProps & {
    text: string;
};

export const BetButtonContainer = styled(ClickRescale)`
    background-image: ${th('control.button')};
    background-size: 100% 100%;
    background-repeat: no-repeat;
    display: inline-block;
    min-width: ${px(286)};
    height: ${px(226)};
    text-align: center;
    box-sizing: border-box;
    font-weight: 700;
    font-size: ${px(48)};
    letter-spacing: ${px(1.2)};
    user-select: none;
`;

export const BetFlexContainer = styled.div`
    display: flex;
    height: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;

export const BetButton: FC<BetButtonProps> = ({ text = '', ...props }) => {
    return (
        <BetButtonContainer {...props}>
            <BetFlexContainer>
                {text.split(' ').map((string, index) => (
                    <span key={index}>{string}</span>
                ))}
            </BetFlexContainer>
        </BetButtonContainer>
    );
};
