import { FC, HTMLAttributes, useCallback } from 'react';
import styled from 'styled-components';
import { th } from '../../lib/common';
import { HeaderIcon } from '../Header/styled';

export const CloseIconCircle = styled(HeaderIcon)`
    background-image: ${th('icons.close')};
`;

export const CloseIcon: FC<HTMLAttributes<HTMLDivElement>> = ({ ...props }) => {
    const onClick = useCallback(() => {
        window.history.back();
    }, []);

    return <CloseIconCircle {...props} onClick={onClick} />;
};
