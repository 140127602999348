import {
    FC,
    HTMLAttributes,
    SyntheticEvent,
    useCallback,
    useMemo,
    useState,
} from 'react';
import { useTheme } from 'styled-components';
import { ReelSpin } from '../../stores';

import { ReelContainer, ReelImage, ReelOutput, ReelSpinner } from './styled';

export type ReelProps = HTMLAttributes<HTMLDivElement> & {
    spin: ReelSpin;
    letters: string[];
    shift: number;
    blink?: boolean;
    spinOffset?: number;
};

export const Reel: FC<ReelProps> = ({
    spin,
    letters: inputLetters,
    shift,
    spinOffset = 0,
    ...props
}) => {
    const {
        'reel.images': images,
        'reel.letters': letters,
        'reel.filler': filler,
    } = useTheme();

    const [firstImage] = images;

    const [height, setHeight] = useState(0);

    const style = useMemo(() => {
        if (height === 0) {
            return { opacity: 0 };
        }
        return {
            height: height * 3 + 16 + 'px',
        };
    }, [height]);

    const onLoad = useCallback((e: SyntheticEvent<HTMLImageElement, Event>) => {
        const img = e.target as HTMLImageElement;
        setHeight(img.clientHeight);
    }, []);

    const assoc = useMemo(() => {
        const result: Record<string, string> = {};

        for (let i = 0; i < letters.length; i++) {
            result[letters[i]] = images[i] || filler;
        }

        return result;
    }, [images, letters, filler]);

    const spinner = useMemo(() => {
        const offsetImages = images
            .slice(spinOffset)
            .concat(images.slice(0, spinOffset));
        const filled = offsetImages.concat(images.slice(0, 3));
        return (
            <ReelSpinner itemHeight={height} size={filled.length}>
                {filled.map((image, index) => (
                    <ReelImage alt="" src={image} key={index} />
                ))}
            </ReelSpinner>
        );
    }, [images, height, spinOffset]);

    const loadHeightImage = useMemo(() => {
        return <ReelImage alt="" src={firstImage} onLoad={onLoad} />;
    }, [firstImage, onLoad]);

    const output = useMemo(() => {
        return (
            <ReelOutput spin={spin} itemHeight={height} shift={shift}>
                {inputLetters.map((letter, i) => (
                    <ReelImage
                        alt={letter}
                        src={assoc[letter] || filler}
                        key={i}
                    />
                ))}
            </ReelOutput>
        );
    }, [assoc, filler, height, inputLetters, shift, spin]);

    const render = useMemo(() => {
        if (!height) {
            return loadHeightImage;
        }

        if (spin === 'spin') {
            return spinner;
        }

        return output;
    }, [spin, height, spinner, loadHeightImage, output]);

    return (
        <ReelContainer {...props} style={style}>
            {render}
        </ReelContainer>
    );
};
