import { FC, HTMLAttributes } from 'react';
import { useSnapshot } from 'reactish-state';
import { Header as HeaderComponent } from '../../components/Header';
import { onTogglePayTable } from '../../stores/paytable';
import * as selectors from '../../stores/selectors';

export const Header: FC<HTMLAttributes<HTMLDivElement>> = (props) => {
    const bank = useSnapshot(selectors.bank);
    const bet = useSnapshot(selectors.bet);
    const win = useSnapshot(selectors.win);
    const gameId = useSnapshot(selectors.gameId);
    const isPayTableOpen = useSnapshot(selectors.isPayTableOpen);
    const isHeaderTopOverlay = useSnapshot(selectors.isHeaderTopOverlay);

    return (
        <HeaderComponent
            {...props}
            bank={bank}
            bet={bet}
            win={win}
            gameId={gameId}
            isPayTableOpen={isPayTableOpen}
            onTogglePayTable={onTogglePayTable}
            topOverlay={isHeaderTopOverlay}
        />
    );
};
