import chunk from 'chunk';
import { store } from '.';
import { createSignalPromise } from '../lib/abort';

export const loadImage = (url: string) =>
    new Promise<void>((resolve) => {
        if (url.startsWith('data:image')) {
            resolve();
            return;
        }

        const rendered = () => {
            console.log('preloaded', url);
            resolve();
        };

        const startRender = () => {
            requestAnimationFrame(rendered);
        };

        const loaded = () => {
            requestAnimationFrame(startRender);
        };

        const image = new Image();
        image.src = url;
        image.onerror = image.onload = loaded;
    });

export const filterBase64 = (url: string) => !url.startsWith('data:image');

export const preload = async (signal?: AbortSignal) => {
    const theme = store.get().theme;

    store.$set((state) => (state.preloading = true));

    const filtered = theme['preload.images']
        .concat(theme['preload.apngs'])
        .filter(filterBase64);

    const chunked = chunk(filtered, 3);

    for (const urls of chunked) {
        await Promise.race([
            Promise.all(urls.map(loadImage)),
            createSignalPromise(signal),
        ]);
    }

    store.$set((state) => (state.preloading = false));
};
