import { ReactNode } from 'react';
import styled, { keyframes } from 'styled-components';
import { px, th } from '../../lib/common';
import { ClickRescale, ClickRescaleProps } from '../ClickRescale';

export type SpinButtonProps = ClickRescaleProps & {
    children: ReactNode;
    stop?: boolean;
    blink?: boolean;
};

export const blink = keyframes`
from { opacity: 0; }
to { opacity: 1; }
`;

export const SpinButton = styled(ClickRescale)<SpinButtonProps>`
    background-image: ${th('control.spin.background-image')};
    border-radius: ${px(300)};
    box-shadow: 0px ${px(10)} 0 ${px(5)} #051d0d,
        inset 0px ${px(-3)} ${px(7)} 0 #fff;
    box-sizing: border-box;
    font-size: ${px(92.5)};
    font-weight: 900;
    letter-spacing: ${px(22.2)};
    margin: ${px(50)} ${px(79)};
    text-align: center;
    height: ${px(175)};
    line-height: ${px(175)};
    width: 100%;
    user-select: none;
    position: relative;
    z-index: ${(props) => (props.stop ? 1 : 0)};
    &:before {
        content: '';
        z-index: -1;
        border-radius: inherit;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-image: ${th('control.spin.stop.background-image')};
        opacity: ${(props) => (props.stop ? 1 : 0)};
        transition: 500ms ease-in-out opacity;
    }

    &:after {
        content: '';
        z-index: -1;
        border-radius: inherit;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-image: ${th('control.spin.blink.background-image')};
        opacity: ${(props) => (props.blink ? 1 : 0)};
        animation: ${blink} 500ms ease-in-out alternate;
        animation-iteration-count: ${(props) => (props.blink ? 'infinite' : 0)};
    }
`;
