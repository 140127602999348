import { FC } from 'react';
import { createGlobalStyle } from 'styled-components';
import { Normalize } from 'styled-normalize';
import { th } from '../../lib/common';

export const GlobalStyleComponent = createGlobalStyle`
    body {
        font-family: ${th('global.font.family')};
        color: ${th('global.foreground')};
    }

    #root {
        position: absolute;
        width: 100%;
        height: 100%;

        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;

        background-image: ${th('background.cover')};
        background-color: ${th('background.color')};
    }
`;

export const GlobalStyle: FC = () => (
    <>
        <Normalize />
        <GlobalStyleComponent />
    </>
);
