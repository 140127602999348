import styled from 'styled-components';
import { px, th } from '../../lib/common';

import { FieldAmount } from '../FieldAmount';

export const ControlsContainer = styled.div`
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
`;

export const BackgroundImageContainer = styled.div`
    background-repeat: no-repeat;
    background-size: cover;
`;

export const TopControlContainer = styled(BackgroundImageContainer)`
    position: relative;
    height: ${px(320)};
    background-color: ${th('control.top.background-color')};
    background-image: ${th('control.top.background-image')};
    box-sizing: border-box;
    padding-top: ${px(20)};
    display: flex;
`;

export const BottomControlContainer = styled(BackgroundImageContainer)`
    position: relative;
    top: ${px(-10)};
    height: ${px(280)};
    background-color: ${th('control.bottom.background-color')};
    background-image: ${th('control.bottom.background-image')};
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const ChangeAmountContainer = styled.div`
    display: flex;
    align-items: center;
    margin-left: ${px(75)};
`;

export const ControlFieldAmount = styled(FieldAmount)`
    margin-left: ${px(28)};
    margin-right: ${px(28)};
`;

export const ControlBetContainer = styled.div`
    display: flex;
    flex: 1;
    justify-content: center;
    box-sizing: border-box;
    padding-top: ${px(35)};
`;

export const ControlBetButtonContainer = styled.div`
    margin-left: ${px(56)};
`;
