import { FC, HTMLAttributes } from 'react';
import { useSnapshot } from 'reactish-state';
import { Controls as ControlsComponent } from '../../components/Controls';
import { betMax, betOne, decrease, increase } from '../../stores/bet';
import * as selectors from '../../stores/selectors';
import { spin, stop } from '../../stores/spin';

export const Controls: FC<HTMLAttributes<HTMLDivElement>> = (props) => {
    const amount = useSnapshot(selectors.amount);
    const decreaseDisabled = useSnapshot(selectors.decreaseDisabled);
    const increaseDisabled = useSnapshot(selectors.increaseDisabled);
    const isSpinning = useSnapshot(selectors.isSpinning);
    const isControlsBlinking = useSnapshot(selectors.isControlsBlinking);

    return (
        <ControlsComponent
            {...props}
            amount={amount}
            blink={isControlsBlinking}
            decreaseDisabled={decreaseDisabled}
            increaseDisabled={increaseDisabled}
            disabled={isSpinning}
            onBetOne={betOne}
            onBetMax={betMax}
            onDecrease={decrease}
            onIncrease={increase}
            onSpin={spin}
            onStop={stop}
        />
    );
};
