export class XFetchError extends Error {
    response: Response;
    constructor(message: string, response: Response) {
        super(message);
        this.response = response;
    }
}

export const xFetch: typeof fetch = async (...args) => {
    const [url, options] = args;
    const response = await fetch(url, {
        ...options,
        headers: {
            ...options?.headers,
            'Cache-Control': 'no-cache',
        },
    });

    if (!response.ok) {
        throw new XFetchError('XFetchError', response);
    }

    return response;
};
