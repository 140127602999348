import { FC, HTMLAttributes, useCallback } from 'react';
import { useSnapshot } from 'reactish-state';
import { PopupError as PopupErrorComponent } from '../../components/PopupError';
import { store } from '../../stores';

export const PopupError: FC<HTMLAttributes<HTMLDivElement>> = (props) => {
    const { error } = useSnapshot(store);

    const onClose = useCallback(() => {
        store.$set((state) => (state.error = ''));
    }, []);

    if (!error) {
        return null;
    }

    return <PopupErrorComponent {...props} error={error} onClose={onClose} />;
};
