import { useMemo } from 'react';

const REEL_SEQUENCE = [-2, -1, 0, 1, 2];

const DEFAULT_WHEELS = ['', '', ''];

export const useWheelSequence = (wheels: string, wins: number[]) =>
    useMemo(() => {
        const $wheels = wheels.match(/.{1,22}/g) || DEFAULT_WHEELS;
        return $wheels.map((wheel, wheelIndex) => {
            const { length } = wheel;
            const winIndex = wins[wheelIndex];
            return REEL_SEQUENCE.map(
                (index) => wheel[(winIndex + index + length) % length]
            );
        });
    }, [wheels, wins]);
