import { useEffect } from 'react';
import { bgm, effects } from '../lib/sounds';

export const useBgm = () => {
    useEffect(() => {
        bgm.play();

        return () => {
            bgm.stop();
            effects.stop();
        };
    }, []);
};
