import { FC, HTMLAttributes } from 'react';
import styled from 'styled-components';
import { useScalable } from '../../hooks/use-scalable';
import { CONTAINER_WIDTH, CONTAINER_HEIGHT, th } from '../../lib/common';

export type RawScalableContainerProps = Partial<{
    relativeWidth: number;
    relativeHeight: number;
}>;

export type ScalableContainerProps = HTMLAttributes<HTMLDivElement> &
    RawScalableContainerProps;

export const Container = styled.div<
    RawScalableContainerProps & { scale: number }
>`
    background-color: ${th('container.background')};
    position: fixed;
    top: 50%;
    right: 0;
    bottom: 0;
    left: 50%;
    transform-origin: top left;
    overflow: hidden;
    ${(props) => ({
        transform: `scale(${props.scale}) translate(-50%, -50%)`,
        width: props.relativeWidth,
        height: props.relativeHeight,
    })}
`;

export const ScalableContainer: FC<ScalableContainerProps> = ({
    relativeWidth = CONTAINER_WIDTH,
    relativeHeight = CONTAINER_HEIGHT,
    ...props
}) => {
    const scale = useScalable(relativeWidth, relativeHeight);
    return (
        <Container
            {...props}
            relativeWidth={relativeWidth}
            relativeHeight={relativeHeight}
            scale={scale}
        />
    );
};
