export const toQuery = (data: Record<string, unknown>) => {
    const params = new URLSearchParams();
    for (const key in data) {
        params.set(key, data[key] + '');
    }
    return params.toString();
};

export const bustedToQuery = (data: Record<string, unknown>) =>
    toQuery({
        _: new Date().getTime(),
        ...data,
    });
