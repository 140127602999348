import { Howl, SoundSpriteDefinitions, Howler } from 'howler';
import bgmURL from '../sounds/bgm.mp3';
import effectsURL from '../sounds/effects.mp3';

export const SOUNDS_SPRITE = {
    'ambient-sound': [0, 71862.85714285713],
    BGM: [73000, 71862.85714285713],
    'big-win': [146000, 12695.510204081642],
    'reel-spin': [160000, 1660.0907029478549],
    'reel-stop': [163000, 888.1632653061331],
    'spin-button': [165000, 313.4693877551058],
    suspense: [167000, 7732.244897959191],
    'win-count': [176000, 1985.306122448975],
    'you-win': [179000, 12695.510204081642],
    BetMax: [193000, 940.4081632653174],
    BetPerLine: [195000, 313.4693877551058],
    Cost: [197000, 313.4693877551058],
    Line1: [199000, 365.7142857142901],
    Line2: [201000, 391.83673469386804],
    Line3: [203000, 391.83673469386804],
    Line4: [205000, 391.83673469386804],
    Line5: [207000, 391.83673469386804],
    Line7: [209000, 365.7142857142901],
    Line9: [211000, 391.83673469386804],
    SeePays: [213000, 287.34693877549944],
    ReelStop: [215000, 574.6938775510273],
    Spin: [217000, 653.0612244897895],
    Win: [219000, 1071.020408163264],
} as const;

export type SpriteHowl = Omit<Howl, 'play'> & {
    play: (id: keyof typeof SOUNDS_SPRITE) => number;
};

export const VOLUME = 0.5;

/**
 * Evaluates if we have muted the application in this session.
 */
export const isMuted = () =>
    window.sessionStorage.getItem('vsf.mute') === 'true';

/**
 * Background music @{link https://www.npmjs.com/package/howler}
 */
export const bgm = new Howl({
    src: bgmURL,
    loop: true,
    volume: VOLUME,
});

export const effects: SpriteHowl = new Howl({
    src: effectsURL,
    sprite: SOUNDS_SPRITE as unknown as SoundSpriteDefinitions,
    volume: VOLUME,
});

export const waitToEnd = (howl: Howl, id: number, signal?: AbortSignal) => {
    const waitSoundEnd = new Promise<void>((resolve) => {
        howl.once(
            'end',
            () => {
                resolve();
            },
            id
        );
    });

    const waitSignalAborted = new Promise<void>((_resolve, reject) => {
        if (!signal) {
            return;
        }

        signal.addEventListener('abort', () => reject());
    });

    return Promise.race([waitSoundEnd, waitSignalAborted]);
};

Howler.mute(isMuted());
