import { useLayoutEffect, useRef, useState } from 'react';

export const getScale = (x1: number, y1: number, x2: number, y2: number) =>
    Math.min(x1 / x2, y1 / y2);

export const useScalable = (width: number, height: number, ms = 5) => {
    const timeout = useRef<NodeJS.Timeout>();

    const [scale, setScale] = useState(0);

    useLayoutEffect(() => {
        const clear = () => clearTimeout(timeout.current);

        const updateSize = () => {
            clear();

            timeout.current = setTimeout(() => {
                setScale(
                    getScale(
                        window.innerWidth,
                        window.innerHeight,
                        width,
                        height
                    )
                );
            }, ms);
        };

        updateSize();

        window.addEventListener('resize', updateSize);

        return () => {
            clear();
            window.removeEventListener('resize', updateSize);
        };
    }, [width, height, ms]);

    return scale;
};
