import { FC, useCallback, useEffect, useState } from 'react';
import { useTheme } from 'styled-components';
import { PreLoadingProps } from './types';

export const PreLoading: FC<PreLoadingProps> = ({
    onClose,
    state,
    ...props
}) => {
    const { PreLoading: ThemedPreLoading } = useTheme();
    const [isClosed, setClose] = useState(false);

    const $onClose = useCallback(() => {
        if (onClose) {
            onClose();
        }
        setClose(true);
    }, [onClose]);

    useEffect(() => {
        if (state === 'open') {
            setClose(false);
        }
    }, [state]);

    if (isClosed) {
        return null;
    }

    return <ThemedPreLoading {...props} onClose={$onClose} state={state} />;
};
