import { FC, HTMLAttributes, ReactNode } from 'react';
import styled, { css, keyframes } from 'styled-components';
import spotlight from '../../images/spotlight.png';

export type ClickRescaleProps = HTMLAttributes<HTMLDivElement> & {
    children?: ReactNode;
    scale?: number;
    disabled?: boolean;
    disabledLook?: boolean;
    spotlight?: boolean;
    spotlightDelay?: number;
    arbitrary?: Record<string, unknown>;
};

export const bgSpotlight = keyframes`
    from { opacity: 0;  }
    to { opacity: 0.75; }
`;

export const cssSpotlight = css`
    &:before {
        content: '';
        z-index: -1;
        position: absolute;
        top: 55%;
        left: 50%;
        width: 60%;
        height: 100%;
        background-image: url('${spotlight}');
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center center;
        transform: translate(-50%, -50%);
        filter: grayscale(100%);
        opacity: 0;
        animation: ${bgSpotlight} 500ms linear infinite alternate;
    }
`;

export const ClickRescaleContainer = styled.div<ClickRescaleProps>`
    position: relative;
    display: inline-block;
    z-index: 0;
    transform: scale(1);
    transition: 100ms linear transform;
    filter: grayscale(${(props) => (props.disabled ? '100%' : '0%')});
    cursor: ${(props) =>
        props.disabled && !props.disabledLook ? 'not-allowed' : 'pointer'};

    &:active {
        transform: scale(${(props) => (props.disabled ? 1 : 0.9)});
    }

    ${(props) => props.spotlight && cssSpotlight};

    &:before {
        animation-delay: ${(props) => props.spotlightDelay || 0}ms;
    }
`;

export const ClickRescale: FC<ClickRescaleProps> = ({
    disabled,
    disabledLook,
    onClick,
    ...props
}) => {
    return (
        <ClickRescaleContainer
            tabIndex={-1}
            {...props}
            disabled={disabled}
            disabledLook={disabledLook}
            onClick={disabled && !disabledLook ? void 0 : onClick}
        />
    );
};
