import {
    FC,
    HTMLAttributes,
    MutableRefObject,
    useCallback,
    useRef,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';
import useOnClickOutside from 'use-onclickoutside';
import {
    PayTableDrawChevron,
    PayTableDrawChevronContainer,
    PayTableDrawClickToSee,
    PayTableDrawContainer,
    PayTableDrawLabel,
    PayTableDrawSeparator,
    PayTableLabelContainer,
} from './styled';

export type PayTableDrawProps = HTMLAttributes<HTMLDivElement> & {
    isOpen?: boolean;
    onTogglePayTable?: (isOpen: boolean) => unknown;
};

export const PayTableDraw: FC<PayTableDrawProps> = ({
    isOpen = false,
    onTogglePayTable,
    ...props
}) => {
    const ref = useRef() as MutableRefObject<HTMLDivElement>;
    const { t } = useTranslation();

    const { PayTableDrawContent } = useTheme();

    const onToggle = useCallback(() => {
        onTogglePayTable && onTogglePayTable(!isOpen);
    }, [onTogglePayTable, isOpen]);

    useOnClickOutside(ref, () => onTogglePayTable && onTogglePayTable(false));

    return (
        <PayTableDrawContainer {...props} ref={ref}>
            <PayTableDrawSeparator />
            <PayTableDrawContent isOpen={isOpen} />
            <PayTableLabelContainer onClick={onToggle}>
                <PayTableDrawChevronContainer>
                    <PayTableDrawChevron isOpen={isOpen} />
                </PayTableDrawChevronContainer>
                <PayTableDrawLabel>
                    <PayTableDrawClickToSee>
                        {t('payTable.' + (isOpen ? 'close' : 'open'))}
                    </PayTableDrawClickToSee>{' '}
                    {t('payTable.table')}
                </PayTableDrawLabel>
                <PayTableDrawChevronContainer>
                    <PayTableDrawChevron isOpen={isOpen} />
                </PayTableDrawChevronContainer>
            </PayTableLabelContainer>
        </PayTableDrawContainer>
    );
};
