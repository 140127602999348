export const getHeaders = () => {
    const params = new URLSearchParams(window.location.search);

    const configId = params.get('configId') as string;

    const sessionId = params.get('sessionId') as string;

    if (!sessionId) {
        throw new Error('No session id');
    }

    if (!configId) {
        throw new Error('No config id');
    }

    return {
        'Content-Type': 'application/json',
        'x-parlay-game-config': 'SL' + configId,
        'x-parlay-session-token': sessionId,
        'x-requested-with': 'XMLHttpRequest',
    };
};
