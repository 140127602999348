import {
    ActionCreator,
    Config,
    createState as createReactishState,
} from 'reactish-state';
import { immer } from 'reactish-state/middleware/immer';

export const createState = <T, A>(
    initialValue: T,
    actionCreators?: ActionCreator<T, A>,
    config?: Config
) => {
    const $createState = createReactishState({
        middleware: immer,
    });

    const state = $createState(initialValue, actionCreators, config);

    const $set = (setter: (value: T) => unknown) =>
        state.set((state) => {
            setter(state);
            return state;
        });

    return {
        ...state,
        $set,
    };
};
