import { Payout } from '../types/api';

export const WIN_STATES = ['LOW_WIN', 'MID_WIN', 'LARGE_WIN'] as const;

export const getWinnings = (winnings: number[]) =>
    winnings.reduce((result, value) => result + value, 0);

export const getWinState = (winLevels: string[][], payouts: Payout[]) => {
    for (let i = winLevels.length - 1; i > -1; i--) {
        for (const payout of payouts) {
            if (winLevels[i].includes(payout.name)) {
                return i + 1;
            }
        }
    }

    return 0;
};
export const getBlinks = (payouts: Payout[]) => {
    const result: [boolean, boolean, boolean] = [false, false, false];

    for (const payout of payouts) {
        for (let i = 0; i < result.length; i++) {
            result[i] = result[i] || payout.symbols[i] !== '-';
        }
    }

    return result;
};

export const parseWheels = (wheels: string, indices: number[]) =>
    indices.map((index, i) => wheels[i * 22 + index]).join('');
