import { store } from '.';
import { spin } from './spin';
import { effects, bgm } from '../lib/sounds';

const { $set } = store;

export const resetWinnings = () =>
    $set((state) => {
        if (effects.playing()) {
            effects.stop();
        }

        if (!bgm.playing()) {
            bgm.play();
        }

        state.payouts = [];
        state.won = false;
        state.winnings = [];
        state.reels.highlights = [];
        state.reels.blinks = [false, false, false];
    });

export const decrease = () => {
    resetWinnings();
    $set((state) => --state.denominationIndex);
    effects.play('Cost');
};

export const increase = () => {
    resetWinnings();
    $set((state) => ++state.denominationIndex);
    effects.play('Cost');
};

export const betOne = () => {
    resetWinnings();
    $set((state) => {
        let lines = Math.max(
            (state.payLines + 1) % (state.configPayLines + 1),
            1
        );

        if (state.configPayLines === 1) {
            lines = Math.max((state.betPerLine + 1) % (state.maxBet + 1), 1);
            state.betPerLine = lines;
        } else {
            state.payLines = lines;
        }

        const id = ('Line' + state.payLines) as `Line${1 | 2 | 3 | 4 | 5}`;
        effects.play(id);
    });
};

export const betMax = async () => {
    resetWinnings();
    $set((state) => {
        const maxBetPerLine = state.maxBet / state.configPayLines;
        state.betPerLine = maxBetPerLine;
        state.payLines = state.configPayLines;
    });
    await spin();
};
