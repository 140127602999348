import { FC, HTMLAttributes } from 'react';
import { useTranslation } from 'react-i18next';
import { ArrowButton } from '../ArrowButton';
import { BetButton } from '../BetButton';
import { SpinButton } from '../SpinButton';
import {
    BottomControlContainer,
    ChangeAmountContainer,
    ControlsContainer,
    ControlFieldAmount,
    TopControlContainer,
    ControlBetContainer,
    ControlBetButtonContainer,
} from './styled';

export type ControlsProps = HTMLAttributes<HTMLDivElement> & {
    amount: string;
    blink: boolean;
    decreaseDisabled: boolean;
    disabled: boolean;
    increaseDisabled: boolean;
    onBetMax?: () => unknown;
    onBetOne?: () => unknown;
    onDecrease?: () => unknown;
    onIncrease?: () => unknown;
    onSpin?: () => unknown;
    onStop?: () => unknown;
};

export const Controls: FC<ControlsProps> = ({
    amount,
    blink,
    decreaseDisabled,
    disabled,
    increaseDisabled,
    onBetMax,
    onBetOne,
    onDecrease,
    onIncrease,
    onSpin,
    onStop,
    ...props
}) => {
    const { t } = useTranslation();

    return (
        <ControlsContainer {...props}>
            <TopControlContainer>
                <ChangeAmountContainer>
                    <ArrowButton
                        flip
                        onClick={onDecrease}
                        disabled={disabled || decreaseDisabled}
                        spotlight={blink}
                        spotlightDelay={500}
                    >
                        -
                    </ArrowButton>
                    <ControlFieldAmount text={amount} />
                    <ArrowButton
                        onClick={onIncrease}
                        disabled={disabled || increaseDisabled}
                        spotlight={blink}
                    >
                        +
                    </ArrowButton>
                </ChangeAmountContainer>
                <ControlBetContainer>
                    <ControlBetButtonContainer>
                        <BetButton
                            onClick={onBetOne}
                            text={t('control.betOne')}
                            disabled={disabled}
                            spotlight={blink}
                            spotlightDelay={500}
                        />
                    </ControlBetButtonContainer>
                    <ControlBetButtonContainer>
                        <BetButton
                            onClick={onBetMax}
                            text={t('control.betMax')}
                            disabled={disabled}
                            spotlight={blink}
                        />
                    </ControlBetButtonContainer>
                </ControlBetContainer>
            </TopControlContainer>
            <BottomControlContainer>
                <SpinButton
                    onClick={disabled ? onStop : onSpin}
                    stop={disabled}
                    blink={blink}
                >
                    {t('control.' + (disabled ? 'stop' : 'spin'))}
                </SpinButton>
            </BottomControlContainer>
        </ControlsContainer>
    );
};
