import { FC } from 'react';
import styled from 'styled-components';
import { px, th } from '../../lib/common';

export type FieldAmountProps = {
    text: string;
};

export const FieldAmountContainer = styled.div`
    background-color: ${th('amount.field.background')};
    border: 2px solid ${th('amount.field.border-color')};
    box-sizing: border-box;
    width: ${px(289)};
    height: ${px(226)};
    font-size: ${px(55)};
    font-weight: 900;
    letter-spacing: ${px(1.38)};
    text-align: center;
    display: inline-block;
`;

export const FieldAmountFlexContainer = styled.div`
    display: flex;
    height: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;

export const FieldAmount: FC<FieldAmountProps> = ({ text, ...props }) => (
    <FieldAmountContainer {...props}>
        <FieldAmountFlexContainer>
            <span>{text}</span>
        </FieldAmountFlexContainer>
    </FieldAmountContainer>
);
