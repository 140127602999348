import { setUser, setExtra, captureException } from '@sentry/react';
import { store } from '.';
import { getHeaders } from '../lib/get-headers';
import { bustedToQuery } from '../lib/to-query';
import { GetGameResponse } from '../types/api';
import { xFetch } from '../lib/xfetch';

const { $set } = store;

export const initialize = async (signal?: AbortSignal) => {
    try {
        $set((state) => {
            state.fetchInitialize = 'loading';
        });

        await xFetch('/api/initialize' + window.location.search, {
            signal,
        });

        const headers = getHeaders();
        const params = bustedToQuery({
            key: headers['x-parlay-game-config'],
        });

        const fetchedGame = await xFetch('/api/getGame?' + params, {
            signal,
            headers,
        });

        const { data, status, msg }: GetGameResponse = await fetchedGame.json();

        $set((state) => {
            if (status === 'success') {
                state.style = data.userGameData.style;
                state.gameId = data.game_id;
                state.sessionId = data.SID;
                state.round = 0;

                state.reels.wheels = data.lastSpin?.wheels || data.wheels;
                state.reels.wins = data.lastSpin?.win_array || [];

                state.maxBet = data.max_bet;
                state.configPayLines = state.payLines = data.pay_lines;
                state.denominationIndex = data.defDenomInd;
                state.denominations = data.denom.map((denom) =>
                    parseInt(denom, 10)
                );

                state.betPerLine = data.max_bet / data.pay_lines;

                state.winnings = [];

                state.currency = data.currencies[data.game_currency];

                state.betKey =
                    data.userGameData.gameType + data.userGameData.configId;
                state.wagerType = data.aWagerType;

                setUser({
                    id: data.userGameData.userId,
                });

                setExtra('sessionId', state.sessionId);
                setExtra('gameId', state.gameId);

                state.cash = data.cashValue;
                state.value = data.accountValue;
                state.bonus = data.bonusBalance;
                state.hasGameLoaded = true;
            } else {
                state.error = msg;
            }
        });
    } catch (error) {
        const e = error as Error;

        if (e?.name === 'AbortError') {
            throw e;
        } else {
            $set((state) => (state.error = 'error.unexpectedError'));
            captureException(error);
        }
    } finally {
        $set((state) => {
            state.fetchInitialize = 'complete';
        });
    }
};
