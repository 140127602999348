import { FC, HTMLAttributes } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { useSnapshot } from 'reactish-state';
import {
    hasGameLoaded as hasGameLoadedState,
    isIframe,
} from '../../stores/selectors';

export type PopupErrorProps = HTMLAttributes<HTMLDivElement> & {
    error: string;
    onClose: () => void;
};

const getErrorKey = (error: string) => {
    if (error.startsWith('error.')) {
        return error;
    }

    return `error.${error}`;
};

export const PopupErrorContainer = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6);
    color: white;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    text-align: center;
`;

export const PopupErrorBody = styled.div`
    background-color: #041809;
    width: 241px;
    padding-top: 44px;
    padding-bottom: 44px;
    border-radius: 12px;
`;

export const PopupContent = styled.div`
    margin-bottom: 20px;
`;

export const PopupErrorButton = styled.div`
    background-color: #99cc00;
    padding: 6px;
    border-radius: 7px;
    display: inline-block;
    cursor: pointer;
    user-select: none;
`;

export const PopupError: FC<PopupErrorProps> = ({
    error,
    onClose,
    ...props
}) => {
    const { t } = useTranslation();

    const key = getErrorKey(error);
    const isInIframe = useSnapshot(isIframe);
    const hasGameLoaded = useSnapshot(hasGameLoadedState);

    return (
        <PopupErrorContainer {...props}>
            <PopupErrorBody>
                <PopupContent>{t(key)}</PopupContent>
                {!isInIframe && hasGameLoaded && (
                    <PopupErrorButton onClick={onClose}>Close</PopupErrorButton>
                )}
            </PopupErrorBody>
        </PopupErrorContainer>
    );
};
