import { FC, ReactNode } from 'react';
import styled from 'styled-components';
import { px, th } from '../../lib/common';
import { ClickRescale, ClickRescaleProps } from '../ClickRescale';

export type ArrowButtonProps = ClickRescaleProps & {
    children: ReactNode;
    flip?: boolean;
};

export const ArrowButtonContainer = styled.div<ArrowButtonProps>`
    width: ${px(114)};
    height: ${px(164)};
    background-image: ${th('control.arrow-button')};
    background-size: contain;
    background-repeat: no-repeat;
    color: white;
    font-size: ${px(60)};
    font-weight: 900;
    line-height: ${px(164)};
    box-sizing: border-box;
    padding-left: ${px(22.5)};
    transform: scale(${(props) => (props.flip ? -1 : 1)});
    user-select: none;
`;

export const ArrowClickRescale = styled(ClickRescale)`
    &:before {
        z-index: 1;
        top: 52.5%;
        left: ${(props) => (props.arbitrary?.flip ? '70%' : '30%')};
        width: 50%;
        height: 50%;
    }
`;

export const ArrowButton: FC<ArrowButtonProps> = ({
    children,
    flip,
    ...props
}) => (
    <ArrowClickRescale {...props} arbitrary={{ flip }}>
        <ArrowButtonContainer flip={flip}>{children}</ArrowButtonContainer>
    </ArrowClickRescale>
);
