import { DefaultTheme } from 'styled-components';

import iconBank from './images/icon-bank.png';
import iconBet from './images/icon-bet.png';
import iconWin from './images/icon-win.png';

import iconClose from './images/icon-close.png';
import iconSound from './images/icon-sound.png';

import iconChevronUp from './images/icon-chevron-up.png';

export default {
    'background.color': '#000000',

    'global.foreground': '#ffffff',
    'global.font.family': "'Roboto', sans-serif",

    'icons.bank': iconBank,
    'icons.bet': iconBet,
    'icons.win': iconWin,

    'icons.sound': iconSound,
    'icons.close': iconClose,

    'icons.chevron-up': iconChevronUp,

    'pay-table.separator.background': '#04150a',
    'pay-table.separator.border-color': '#ffffff',
    'pay-table.click.color': '#ddcb29',

    'preload.images': [
        iconBank,
        iconBet,
        iconWin,
        iconClose,
        iconSound,
        iconChevronUp,
    ],
    'preload.apngs': [] as string[],

    'reel.letters': ['a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i'],

    'pay-line.color': 'red',
} as DefaultTheme;
