import { init } from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';

export type SiteData = Partial<{
    dsn: string;
    tracesSampleRate: string;
    release: string;
    environment: string;
}>;

export const getTracesSampleRate = (data: SiteData) => {
    const parsed = parseInt(data.tracesSampleRate || '', 10);
    return isNaN(parsed) ? 1.0 : parsed;
};

export const initialize = async () => {
    try {
        const response = await fetch('/sentry.io.json', {
            headers: {
                'Cache-Control': 'no-cache',
            },
        });

        if (!response.ok) {
            throw new Error('Failed to fetch /sentry.io.json');
        }

        const data: SiteData = await response.json();

        if (!data.dsn) {
            throw new Error('dsn has not pbeen provided');
        }

        const integrations = [new BrowserTracing()];

        init({
            ...data,
            tracesSampleRate: getTracesSampleRate(data),
            integrations,
        });
    } catch (error) {}
};
