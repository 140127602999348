import { useEffect } from 'react';
import { boot } from '../stores/boot';

export const useBoot = () => {
    useEffect(() => {
        const controller = new AbortController();

        boot(controller.signal).catch(() => {});

        return () => {
            controller.abort();
        };
    }, []);
};
