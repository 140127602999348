import { FC } from 'react';
import { useSnapshot } from 'reactish-state';
import { Reels as ReelsComponent } from '../../components/Reels';
import { store } from '../../stores';

export const Reels: FC = () => {
    const { reels, payLines, betPerLine } = useSnapshot(store);

    return (
        <ReelsComponent
            {...reels}
            lines={payLines}
            payLinesThickness={betPerLine}
        />
    );
};
