import { WheelShift } from '../types/api';

export const NORMAL_SHIFTS = {
    d: -1,
    n: 0,
    u: 1,
} as const;

/**
 * Returns an array of randomized offset numbers.
 * @param length The number of offsets to create.
 * @param size The maximum value of each offset.
 */
export const getSpinOffsets = (length: number, size: number) => {
    const offsets: number[] = [];

    if (size < 1 || length < 1) {
        throw new Error(
            `getSpinOffset(length: ${length}, size: ${size}): length or size must be >= 1.`
        );
    }

    for (let i = 0; i < length; ) {
        const number = Math.floor(Math.random() * size);
        if (offsets.includes(number)) {
            continue;
        }
        offsets.push(number);
        i++;
    }

    return offsets;
};

/**
 * Returns an array of shift offsets.
 * @param shifts An array of shift symbols.
 * @param ordinal The ordinal direction of how an offset shifts an index.
 */
export const getShifts = (shifts: WheelShift[], ordinal: -1 | 1 = 1) =>
    shifts.map((shift) => NORMAL_SHIFTS[shift] * ordinal);
