import delay from 'delay';
import { store } from '.';

export const onTogglePayTable = async (isOpen: boolean) => {
    const controller = new AbortController();
    const key = 'paytable:toggle';

    store.$set((state) => {
        const previous = state.isPayTableOpen;
        state.isPayTableOpen = isOpen;
        state.aborters[key] = controller;
        state.isPayTableAnimating = previous !== isOpen;
    });

    await delay(200);

    store.$set((state) => {
        state.isPayTableAnimating = false;
        delete state.aborters[key];
    });
};
