import { FC, HTMLAttributes, useEffect, useMemo, useRef } from 'react';
import { useWheelSequence } from '../../hooks/use-wheel-sequence';
import {
    OverlayReel,
    OverlayReelContainer,
    ReelsContainer,
    ReelsMainContainer,
    ReelsOverlay,
    ReelsPayLines,
} from './styled';

import { StoreState } from '../../stores';
import { useDelayedRender } from '../../hooks/use-delayed-render';

export type RawReelsProps = StoreState['reels'] & {
    lines: number;
    payLinesThickness: number;
    shifts: number[];
    overlays: boolean[];
    spinOffsets: number[];
};

export type ReelsProps = HTMLAttributes<HTMLDivElement> & RawReelsProps;

export const Reels: FC<ReelsProps> = ({
    wheels: $wheels,
    wins,
    lines,
    suspense,
    highlights,
    blinks,
    spins,
    shifts,
    overlays,
    payLinesThickness,
    spinOffsets,
    ...props
}) => {
    const ref = useRef(true);
    const wheels = useWheelSequence($wheels, wins);

    const isFinishedSpinning = useMemo(
        () => !spins.some((spin) => spin === 'spin') && !suspense,
        [spins, suspense]
    );

    const isPayLinesVisible = useDelayedRender(isFinishedSpinning, 1800);

    useEffect(() => {
        ref.current = false;
    }, []);

    return (
        <>
            <ReelsMainContainer overlay={suspense}>
                <ReelsContainer {...props}>
                    {wheels.map((wheel, i) => (
                        <OverlayReelContainer key={i}>
                            <OverlayReel
                                letters={wheel}
                                spin={spins[i]}
                                overlay={overlays[i]}
                                blink={blinks[i]}
                                shift={shifts[i] || 0}
                                spinOffset={spinOffsets[i] || 0}
                            />
                        </OverlayReelContainer>
                    ))}
                    <ReelsPayLines
                        lines={lines}
                        highlights={highlights}
                        visible={
                            ref.current ||
                            (isFinishedSpinning && isPayLinesVisible)
                        }
                        thickness={payLinesThickness}
                    />
                </ReelsContainer>
            </ReelsMainContainer>
            {suspense && <ReelsOverlay />}
        </>
    );
};
