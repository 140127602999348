import styled, { css, keyframes } from 'styled-components';
import { px } from '../../lib/common';
import { PayLines } from '../PayLines';
import { Reel } from '../Reel';

export const fadeInOverlay = keyframes`
    from {
        background-color: rgba(0, 0, 0, 0);
    }
    to {
        background-color: rgba(0, 0, 0, 0.5);
    }
`;

export const cssFadeInPayLines = css`
    opacity: 1;
    transition: opacity 500ms ease-in;
`;

export const ReelsMainContainer = styled.div<{ overlay: boolean }>`
    position: absolute;
    bottom: ${px(640)};
    left: 0;
    width: 100%;
    user-select: none;
`;

export const ReelsContainer = styled.div`
    display: flex;
    box-sizing: border-box;
`;

export const ReelsOverlay = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    opacity: 1;
    animation: 1s ease-in ${fadeInOverlay};
`;

export const OverlayReel = styled(Reel)<{ overlay: boolean }>`
    width: 80%;
    z-index: ${(props) => (props.overlay ? 1 : 0)};
`;

export const OverlayReelContainer = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    &:nth-child(1) {
        left: 30px;
    }
    &:nth-child(3) {
        left: -30px;
    }
`;

export const ReelsPayLines = styled(PayLines)<{ visible: boolean }>`
    width: 85%;
    left: 7.5%;
    opacity: 0;
    ${(props) => props.visible && cssFadeInPayLines};
`;
