import { useEffect, useRef, useState } from 'react';

export const useDelayedRender = (isRendered: boolean, ms: number) => {
    const ref = useRef<NodeJS.Timeout>();
    const [isFullyRendered, setFullyRendered] = useState(false);

    useEffect(() => {
        ref.current = setTimeout(() => {
            setFullyRendered(isRendered);
        }, ms);

        return () => {
            clearTimeout(ref.current);
        };
    }, [isRendered, ms]);

    return isFullyRendered;
};
