import { FC, HTMLAttributes } from 'react';
import styled, { css, keyframes, useTheme } from 'styled-components';

export type PayLinesProps = {
    lines: number;
    highlights: Array<'true' | 'false'>;
    thickness: number;
};

export const LINES = [
    [0, 50, 100, 50],
    [0, 18.75, 100, 18.75],
    [0, 81.25, 100, 81.25],
    [0, 0, 100, 100],
    [0, 100, 100, 0],
];

export const STROKE_WIDTH_NORMAL = 3;
export const STROKE_WIDTH_HIGHLIGHT = 7;
export const STROKE_THICKNESS = 1.5;

export const highlight = keyframes`
    0% { stroke-width: ${STROKE_WIDTH_NORMAL}; }
    100% { stroke-width: ${STROKE_WIDTH_HIGHLIGHT}; }
`;

export const cssHighlight = css`
    animation: ${highlight} 1s ease-in-out infinite alternate;
`;

export const PayLinesContainer = styled.svg`
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
`;

PayLinesContainer.defaultProps = {
    viewBox: '0 0 100 100',
    preserveAspectRatio: 'none',
};

export const Line = styled.line<{
    highlight: string;
    visible: boolean;
    thickness: number;
}>`
    opacity: ${(props) => {
        if (props.visible) {
            return props.highlight === 'true' ? 1 : 0.3;
        }
        return 0;
    }};

    transition: 500ms ease-in-out opacity, 1s ease-in-out stroke-width;

    stroke-width: ${(props) =>
        props.thickness * STROKE_THICKNESS + STROKE_WIDTH_NORMAL};

    ${(props) => props.highlight === 'true' && cssHighlight};
`;

Line.defaultProps = {
    vectorEffect: 'non-scaling-stroke',
};

export const PayLines: FC<HTMLAttributes<HTMLOrSVGElement> & PayLinesProps> = ({
    lines,
    highlights,
    thickness,
    ...props
}) => {
    const { 'pay-line.color': color } = useTheme();

    return (
        <PayLinesContainer {...props}>
            {LINES.map(([x1, y1, x2, y2], index) => (
                <Line
                    thickness={highlights[index] === 'true' ? thickness : 0}
                    visible={index + 1 <= lines}
                    key={index}
                    x1={x1}
                    y1={y1}
                    x2={x2}
                    y2={y2}
                    stroke={color}
                    highlight={highlights[index]}
                />
            ))}
        </PayLinesContainer>
    );
};
