import { FC, ReactNode } from 'react';
import { useSnapshot } from 'reactish-state';
import { ThemeProvider as PackageThemeProvider } from 'styled-components';
import { store } from '../../stores';

export type ThemeProviderProps = {
    children?: ReactNode;
};

export const ThemeProvider: FC<ThemeProviderProps> = (props) => {
    const { theme, themeLoading } = useSnapshot(store);

    if (themeLoading) {
        return null;
    }

    return <PackageThemeProvider {...props} theme={theme} />;
};
