import { I18nextProvider } from 'react-i18next';
import { GlobalStyle } from './components/GlobalStyle';
import { ScalableContainer } from './components/ScalableContainer';
import { AppContainer } from './containers/AppContainer';
import { Controls } from './containers/Controls';
import { Header } from './containers/Header';
import { PopupError } from './containers/PopupError';
import { PreLoading } from './containers/PreLoading';
import { Reels } from './containers/Reels';
import { ThemeProvider } from './containers/ThemeProvider';
import { useAbort } from './hooks/use-abort';
import { useBgm } from './hooks/use-bgm';
import { useBoot } from './hooks/use-boot';
import i18n from './lib/i18next';

export default function App() {
    useBgm();
    useBoot();
    useAbort();

    return (
        <ThemeProvider>
            <GlobalStyle />
            <ScalableContainer>
                <I18nextProvider i18n={i18n}>
                    <AppContainer>
                        <Controls />
                        <Header />
                        <Reels />
                    </AppContainer>
                    <PreLoading />
                    <PopupError />
                </I18nextProvider>
            </ScalableContainer>
        </ThemeProvider>
    );
}
