import { DefaultTheme } from 'styled-components';

export const BASE_WIDTH = 1440;

export const BASE_HEIGHT = 2960;

export const CONTAINER_WIDTH = 584;

export const CONTAINER_HEIGHT = 1200;

export const th =
    (key: keyof DefaultTheme) =>
    ({ theme }: { theme: DefaultTheme }) => {
        if (theme) {
            const v = theme[key];
            if (typeof v === 'string') {
                if (
                    v.match(/data:image\/(png|jpg)/) ||
                    v.match(/\.(png|jpg)/)
                ) {
                    return `url("${v}")`;
                }

                return v;
            }
        }
    };

export const rpx = (px: number) => (px / BASE_WIDTH) * CONTAINER_WIDTH;

export const px = (px: number) => rpx(px) + 'px';

export const DRAGON_BONES_WIDTH = rpx(BASE_WIDTH);

export const DRAGON_BONES_HEIGHT = rpx(BASE_HEIGHT);

export const DRAGON_BONES_OFFSET_X = DRAGON_BONES_WIDTH / 2;

export const DRAGON_BONES_OFFSET_Y = DRAGON_BONES_HEIGHT / 2;
