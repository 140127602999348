import { formatCurrency } from '@parlaygames/currency';
import { selector } from 'reactish-state';

import { store } from '.';
import { getWinnings, getWinState } from '../lib/get-win-state';

export const gameId = selector(
    store,
    (state) => state.sessionId + '-' + state.round
);

export const denomination = selector(
    store,
    (state) => state.denominations[state.denominationIndex]
);

export const winnings = selector(store, (state) => getWinnings(state.winnings));

export const cash = selector(store, (state) => {
    if (state.wagerType === '2') {
        return state.bonus;
    } else if (state.wagerType === '1') {
        return state.cash;
    }

    return state.value;
});

export const bank = selector(store, cash, (state, $cash) => {
    return formatCurrency($cash / 100, state.currency);
});

export const bet = selector(store, denomination, (state, denomination) =>
    formatCurrency(
        (denomination * state.payLines * state.betPerLine) / 100,
        state.currency
    )
);

export const win = selector(
    store,
    winnings,
    denomination,
    (state, winnings, denomination) =>
        formatCurrency(
            winnings * (denomination / 100) * state.betPerLine,
            state.currency
        )
);

export const amount = selector(store, denomination, (state, denomination) =>
    formatCurrency(denomination / 100, state.currency)
);

export const decreaseDisabled = selector(
    store,
    (state) => state.denominationIndex === 0
);

export const increaseDisabled = selector(
    store,
    (state) => state.denominationIndex === state.denominations.length - 1
);

export const isSpinning = selector(store, (state) =>
    state.reels.spins.some((spin) => spin !== 'stop')
);

export const isPreloading = selector(store, (state) => {
    const isLoading = state.preloading || state.fetchInitialize !== 'complete';
    if (isLoading) {
        return true;
    }

    return !state.sessionId;
});

export const winLevel = selector(store, (state) =>
    getWinState(state.theme['win.levels'] || [], state.payouts)
);

export const isControlsBlinking = selector(winLevel, (level) => level > 1);

export const isPayTableOpen = selector(store, (state) => state.isPayTableOpen);

export const isPayTableAnimating = selector(
    store,
    (state) => state.isPayTableAnimating
);

export const suspense = selector(store, (state) => state.reels.suspense);

export const isHeaderTopOverlay = selector(
    isPayTableAnimating,
    isPayTableOpen,
    suspense,
    (isPayTableAnimating, isPayTableOpen, suspense) => {
        return suspense ? isPayTableOpen || isPayTableAnimating : true;
    }
);

export const isIframe = selector(store, (state) => state.isIframe);
export const hasGameLoaded = selector(store, (state) => state.hasGameLoaded);
