import { Howler } from 'howler';
import { FC, HTMLAttributes, useCallback, useState } from 'react';
import styled from 'styled-components';
import { th } from '../../lib/common';
import { effects, isMuted } from '../../lib/sounds';
import { HeaderIcon } from '../Header/styled';

export const SoundIconCircle = styled(HeaderIcon)<{ disabled?: boolean }>`
    background-image: ${th('icons.sound')};
    filter: ${(props) => (props.disabled ? 'grayscale(100%)' : '')};
`;

export const SoundIcon: FC<HTMLAttributes<HTMLDivElement>> = () => {
    const [mute, setMute] = useState(isMuted);

    const onClick = useCallback(() => {
        setMute((mute) => {
            const $mute = !mute;
            sessionStorage.setItem('vsf.mute', $mute + '');

            if ($mute) {
                effects?.once('end', () => {
                    Howler.mute($mute);
                });
            } else {
                Howler.mute($mute);
            }

            effects?.play('SeePays');

            return $mute;
        });
    }, [setMute]);

    return <SoundIconCircle disabled={mute} onClick={onClick} />;
};
